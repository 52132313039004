import { ConnectionType } from 'graphql/generated';
import { unreachable } from 'utils/unreachable';

const connectionToConfigurationType: Record<ConnectionType, ConfigurationType> =
  {
    /* Connections configured via URL published metadata */
    [ConnectionType.Adfssaml]: 'MetadataUrl',
    [ConnectionType.Auth0Saml]: 'MetadataUrl',
    [ConnectionType.AzureSaml]: 'MetadataUrl',
    [ConnectionType.Cassaml]: 'MetadataUrl',
    [ConnectionType.CyberArkSaml]: 'MetadataUrl',
    [ConnectionType.DuoSaml]: 'MetadataUrl',
    [ConnectionType.GenericSaml]: 'MetadataUrl',
    [ConnectionType.KeycloakSaml]: 'MetadataUrl',
    [ConnectionType.OktaSaml]: 'MetadataUrl',
    [ConnectionType.PingOneSaml]: 'MetadataUrl',
    [ConnectionType.VMwareSaml]: 'MetadataUrl',

    /* Connections configured via XML metadata */
    [ConnectionType.GoogleSaml]: 'MetadataXml',
    [ConnectionType.JumpCloudSaml]: 'MetadataXml',
    [ConnectionType.OneLoginSaml]: 'MetadataXml',
    [ConnectionType.PingFederateSaml]: 'MetadataXml',

    /* Connections not configured for now */
    [ConnectionType.RipplingSaml]: 'Manual',
    [ConnectionType.SalesforceSaml]: 'Manual',
    [ConnectionType.ShibbolethSaml]: 'Manual',

    /* Connections that will always require manual configuration */
    [ConnectionType.AdpOidc]: 'Manual',
    [ConnectionType.GenericOidc]: 'Manual',

    /* Connections that are instead configured globally */
    [ConnectionType.GoogleOAuth]: 'None',
    [ConnectionType.MagicLink]: 'None',
    [ConnectionType.MicrosoftOAuth]: 'None',
  };

export type ConfigurationType =
  | 'MetadataUrl'
  | 'MetadataXml'
  | 'Manual'
  | 'None';

export const configurationTypeForConnectionType = (
  connectionType: ConnectionType,
  {
    isCertificateMetadataInputEnabled,
    isOktaOINEnabled,
  }: {
    isCertificateMetadataInputEnabled: boolean;
    isOktaOINEnabled: boolean;
  },
): ConfigurationType => {
  /* Okta OIN is enabled as a step "override", which means the `connectionType` is
   * still `OktaSaml`, but the step we show askes the user to instead upload an XML
   * file.  So when we detect that Okta OIN is enabled, we _also_ need to _override_
   * the configuration type here.
   *
   * TODO: Refactor the OIN step to use a `MetadataUrl`, similar to the regular
   * `OktaSaml` flow.
   */
  if (connectionType === ConnectionType.OktaSaml && isOktaOINEnabled) {
    return 'MetadataXml';
  }

  const configurationType = connectionToConfigurationType[connectionType];

  /* Map `MetadataUrl` and `MetadataXml` configuration types back to
   * `Manual` if the feature flag is not enabled. This can be removed
   * once the flag has been enabled globally.
   */
  switch (configurationType) {
    case 'None':
    case 'Manual':
      return configurationType;
    case 'MetadataUrl':
    case 'MetadataXml':
      if (isCertificateMetadataInputEnabled) {
        return configurationType;
      }

      return 'Manual';
    default:
      return unreachable(configurationType);
  }
};
